import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import React from 'react';
import Nav from 'components/Nav';
import { useUser } from '@auth0/nextjs-auth0/client';
import { Box } from '@mui/material';

const BaseWithNav: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoading } = useUser();
  return (
    <>
      <Nav />
      {isLoading && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      <Box mb={3}>{!isLoading && <div>{children}</div>}</Box>
    </>
  );
};

export default BaseWithNav;
