/* eslint-disable @next/next/no-img-element */
import { styled } from '@mui/material/styles';
import { AppBar, Avatar, Button, Toolbar } from '@mui/material';

import React from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import Link from './Link';

const PREFIX = 'Nav';

const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonText: `${PREFIX}-menuButtonText`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.menuButtonText}`]: {
    color: theme.palette.common.white,
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
}));

const Nav: React.FunctionComponent = () => {
  const { user, isLoading } = useUser();

  return (
    <Root className={classes.root}>
      <AppBar position="static" color="primary" enableColorOnDark sx={{ bgcolor: 'promo.main' }}>
        <Toolbar>
          <div className={classes.title}>
            <Link href="/" passHref>
              <Button color="inherit">
                <img src="/logo.png" alt="Posos DataHub" height="32" />
              </Button>
            </Link>
          </div>
          {!isLoading && !user && (
            <div className={classes.menuButton}>
              <Link href="/api/auth/login" noLinkStyle>
                <Button className={classes.menuButtonText}>Login</Button>
              </Link>
            </div>
          )}
          {!isLoading && user
            ? [
                <div className={classes.menuButton} key="logout">
                  <Link href="/api/auth/logout" noLinkStyle>
                    <Button className={classes.menuButtonText}>Logout</Button>
                  </Link>
                </div>,
                <div className={classes.menuButton} key="avatar">
                  {user && <Avatar src={user.picture} />}
                </div>,
              ]
            : null}
        </Toolbar>
      </AppBar>
    </Root>
  );
};

export default Nav;
