import { Card, CardActionArea, CardHeader, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { green, red, orange, lightBlue } from '@mui/material/colors';
import Link from 'next/link';

const PREFIX = 'PipelineStatusCard';

const classes = {
  default: `${PREFIX}-default`,
  success: `${PREFIX}-success`,
  warning: `${PREFIX}-warning`,
  error: `${PREFIX}-error`,
  info: `${PREFIX}-info`,
};

const Root = styled('div')({
  [`& .${classes.default}`]: {},
  [`& .${classes.success}`]: {
    margin: 10,
    color: '#fff',
    backgroundColor: green[500],
  },
  [`& .${classes.warning}`]: {
    margin: 10,
    color: '#fff',
    backgroundColor: orange[500],
  },
  [`& .${classes.error}`]: {
    margin: 10,
    color: '#fff',
    backgroundColor: red[500],
  },
  [`& .${classes.info}`]: {
    margin: 10,
    color: '#fff',
    backgroundColor: lightBlue[400],
  },
});

interface PipelineStatusCardProps {
  label: React.ReactNode;
  status: 'default' | 'success' | 'error' | 'warning' | 'info';
  icon: React.ReactNode;
  subheader: React.ReactNode;
  href: string;
  as?: string;
}

const PipelineStatusCard: React.FunctionComponent<PipelineStatusCardProps> = ({
  label,
  status,
  icon,
  subheader,
  href,
  as,
}) => {
  return (
    <Root>
      <Card>
        <Link href={href} as={as} passHref legacyBehavior>
          <CardActionArea>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes[status]}>
                  {icon}
                </Avatar>
              }
              title={label}
              subheader={subheader}
            />
          </CardActionArea>
        </Link>
      </Card>
    </Root>
  );
};
export default PipelineStatusCard;
