import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import React from 'react';
import { CardActionArea } from '@mui/material';

const PREFIX = 'PososCard';

const classes = {
  root: `${PREFIX}-root`,
  actionArea: `${PREFIX}-actionArea`,
};

const Root = styled('div')({
  [`& .${classes.root}`]: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    border: 0,
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    color: 'white',
    '&:hover': {
      boxShadow: '0 3px 15px 8px rgba(33, 203, 243, .3)',
      cursor: 'pointer',
    },
  },
  [`& .${classes.actionArea}`]: {
    padding: '0 30px',
  },
});

interface Props {
  title: string;
  description: string;
  href: string;
  as?: string;
}

// eslint-disable-next-line prettier/prettier
const PososCard: React.FunctionComponent<Props> = ({ title, description, href, as }) => {
  return (
    <Root>
      <Link href={href} as={as} passHref legacyBehavior>
        <Card className={classes.root}>
          <CardActionArea className={classes.actionArea}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Model
              </Typography>
              <Typography variant="h5" component="h2">
                {title}
              </Typography>
              <Typography>{description}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Root>
  );
};

export default PososCard;
