import { Container, Grid, Typography } from '@mui/material';

import BaseWithNav from 'containers/BaseWithNav';
import CompareIcon from '@mui/icons-material/Compare';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { NextPage } from 'next';
import PipelineStatus from 'models/PipelineStatus';
import PipelineStatusCard from 'components/PipelineStatusCard';
import PososCard from 'components/SectionCard';
import React from 'react';
import useSWR from 'swr';
import { useUser } from '@auth0/nextjs-auth0/client';
import { CompareArrows } from '@mui/icons-material';

const Home: NextPage = () => {
  const { user, error, isLoading } = useUser();
  const { data: status } = useSWR<PipelineStatus>(() =>
    !isLoading && user ? `/api/pipeline-status/` : ''
  );
  if (error) return <div>{error.message}</div>;

  const dagRunsStatus: 'default' | 'success' | 'error' | 'warning' | 'info' =
    status && status.dag_runs && status.dag_runs.failed === 0 && status.dag_runs.warning === 0
      ? 'success'
      : 'warning';
  let datasetVersionsStatus: 'default' | 'success' | 'error' | 'warning' | 'info' = 'default';
  if (status) {
    datasetVersionsStatus = 'success';
    if (status.dataset_versions) {
      if (status.dataset_versions.not_validated > 0) {
        datasetVersionsStatus = 'error';
      } else if (status.dataset_versions.not_processed > 0) {
        datasetVersionsStatus = 'warning';
      }
    }
  }
  const indexConfigurationStatus: 'default' | 'success' | 'error' | 'warning' | 'info' =
    status && status.missing_datasets && status.missing_datasets.length < 1 ? 'success' : 'info';

  return (
    <BaseWithNav>
      <Container component="main" maxWidth="xl">
        <div className="hero">
          {!isLoading && user && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4" component="h2" gutterBottom={false}>
                  Welcome to Posos DataHub
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h3" gutterBottom={false}>
                  Pipeline Health
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <PipelineStatusCard
                      label={<Typography variant="h6">Dag Run Status</Typography>}
                      status={dagRunsStatus}
                      icon={<PlayArrowIcon />}
                      href=""
                      subheader={
                        dagRunsStatus === 'success'
                          ? 'All recent dag runs have succeeded'
                          : `${
                              status?.dag_runs?.failed + status?.dag_runs?.warning
                            } dag runs did not succeed`
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <PipelineStatusCard
                      label={<Typography variant="h6">Dataset Versions Validation</Typography>}
                      status={datasetVersionsStatus}
                      icon={<CompareIcon />}
                      href="/dataset-versions"
                      subheader={
                        datasetVersionsStatus === 'success'
                          ? 'All published datasets are validated'
                          : `${
                              status?.dataset_versions.not_validated +
                              status?.dataset_versions.not_processed
                            } datasets have ongoing validation issues`
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <PipelineStatusCard
                      label={<Typography variant="h6">Dataset {'<>'} Index check</Typography>}
                      status={indexConfigurationStatus}
                      icon={<CompareArrows />}
                      as={
                        status?.missing_datasets !== undefined
                          ? 'index/document-index-staging/'
                          : ''
                      }
                      href={status?.missing_datasets !== undefined ? 'index/[indexSlug]/' : ''}
                      subheader={
                        indexConfigurationStatus === 'success'
                          ? 'All datasets are included in document-index-staging'
                          : `${status?.missing_datasets?.length} datasets are not included in document-index-staging`
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h3" gutterBottom={false}>
                  Dashboards
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <PososCard
                      title="Sources"
                      description="A source is an object representing a location/series of files/set of data that can be used by Posos in various ways."
                      href="/sources"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <PososCard
                      title="Datasets"
                      description="A dataset is a group of documents. Each dataset has multiple versions."
                      href="/datasets"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <PososCard
                      title="Realms"
                      description="A realm is the object holding the configuration for an application using DataHub data."
                      href="/realms"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <PososCard
                      title="DAGs"
                      description="A DAG (Directed acyclic graph) is a group of task executed in a very specific order."
                      href="/dags"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <PososCard
                      title="DAG Runs"
                      description="A DAG Run is a specific execution of the group of task within a DAG."
                      href="/dag-runs"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <PososCard
                      title="Index"
                      description="An Index used to request documents in one of our frontends"
                      href="/indexes"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <PososCard
                      title="Tags"
                      description="A tag is used to add relevant filterable informations on an upstream or document"
                      href="/tags"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <PososCard
                      title="Upstreams"
                      description="An upstream is the raw content of a resource at a specific point in time without any parsing done. This can be a file or a way for us to group document together."
                      href="/upstreams"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <PososCard
                      title="Audit"
                      description="Auditing of the semi-automatic and manual documents"
                      href="/audit"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </Container>
    </BaseWithNav>
  );
};

export default Home;
